<template>
<h1 class="text-center">Cards</h1>

  <ul>
    <li class=" text-center text-3xl my-2">
      NR. 1
      <CardsNr1/>
    </li>
    <li class=" text-center text-3xl my-2">
      NR. 2
      <CardsNr2/>
    </li>


    <li class=" text-center text-3xl mb-2">
      NR. 3
      <CardsNr3/>
    </li>

  </ul>

</template>

<script>

import CardsNr1 from "@/components/partials/Cards/CardsNr1";
import CardsNr2 from "@/components/partials/Cards/CardsNr2";
import CardsNr3 from "@/components/partials/Cards/CardsNr3";
export default {
  name: "Cards",

  components: {CardsNr1,CardsNr2,CardsNr3},

}
</script>

<style scoped>

</style>
