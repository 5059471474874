<template>

  <div id="app" class="container">
    <card data-image="https://images.unsplash.com/photo-1479660656269-197ebb83b540?dpr=2&auto=compress,format&fit=crop&w=1199&h=798&q=80&cs=tinysrgb&crop=">

      <template v-slot:header>

      <h1>Canyons</h1>
      </template>
      <template v-slot:content>

      <p class="mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>

      </template>
    </card>
    <card data-image="https://images.unsplash.com/photo-1479659929431-4342107adfc1?dpr=2&auto=compress,format&fit=crop&w=1199&h=799&q=80&cs=tinysrgb&crop=">

      <template v-slot:header>

      <h1 >Beaches</h1>
      </template>
      <template v-slot:content>

        <p class="mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
      </template>
    </card>
    <card data-image="https://images.unsplash.com/photo-1479644025832-60dabb8be2a1?dpr=2&auto=compress,format&fit=crop&w=1199&h=799&q=80&cs=tinysrgb&crop=">
      <template v-slot:header>

      <h1 >Trees</h1>
      </template>
      <template v-slot:content>
        <p class="mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>

      </template>
    </card>
    <card data-image="https://images.unsplash.com/photo-1479621051492-5a6f9bd9e51a?dpr=2&auto=compress,format&fit=crop&w=1199&h=811&q=80&cs=tinysrgb&crop=">

      <template v-slot:header>
      <h1>Lakes</h1>
        </template>

      <template v-slot:content>
        <p class="mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
      </template>
    </card>
  </div>

</template>

<script>
import card from "@/components/partials/Cards/CardsNr2/card";


export default {
  name: "CardsNr2",

components: {card}

}
</script>

<style scoped lang="scss">
$hoverEasing: cubic-bezier(0.23, 1, 0.32, 1);
$returnEasing: cubic-bezier(0.445, 0.05, 0.55, 0.95);


.card-info p {
  line-height: 1.5em;
}

.card-info h1+p, p+p {
  margin-top: 10px;
}

.container {
  padding: 40px 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-wrap {
  margin: 10px;
  transform: perspective(800px);
  transform-style: preserve-3d;
  cursor: pointer;
  // background-color: #fff;

  &:hover {
    .card-info {
      transform: translateY(0);
    }
    .card-info p {
      opacity: 1;
    }
    .card-info, .card-info p {
      transition: 0.6s $hoverEasing;
    }
    .card-info:after {
      transition: 5s $hoverEasing;
      opacity: 1;
      transform: translateY(0);
    }
    .card-bg {
      transition:
          0.6s $hoverEasing,
          opacity 5s $hoverEasing;
      opacity: 0.8;
    }
    .card {
      transition:
          0.6s $hoverEasing,
          box-shadow 2s $hoverEasing;
      box-shadow:
          rgba(white, 0.2) 0 0 40px 5px,
          rgba(white, 1) 0 0 0 1px,
          rgba(black, 0.66) 0 30px 60px 0,
          inset #333 0 0 0 5px,
          inset white 0 0 0 6px;
    }
  }
}

.card-info {
  padding: 20px;
  position: absolute;
  bottom: 0;
  color: #fff;
  transform: translateY(40%);
  transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);

  p {
    opacity: 0;
    text-shadow: rgba(black, 1) 0 2px 3px;
    transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  * {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0; left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 0%, rgba(#000, 0.6) 100%);
    background-blend-mode: overlay;
    opacity: 0;
    transform: translateY(100%);
    transition: 5s 1s $returnEasing;
  }
}

.card-info h1 {
  font-family: "Playfair Display";
  font-size: 36px;
  font-weight: 700;
  text-shadow: rgba(black, 0.5) 0 10px 10px;
}

.card-info p {
  font-family: "Playfair Display";
  font-size: 15px;
  text-align: left;
  margin-left: 3px;

}


</style>
