<template>

  <div class="options mx-auto mt-8">


    <div

        class="option active"
        @click="click"
        ref="div1"
        id="1"
        style="--optionBackground:url(https://66.media.tumblr.com/6fb397d822f4f9f4596dff2085b18f2e/tumblr_nzsvb4p6xS1qho82wo1_1280.jpg);">
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">


          <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="walking"
              class="svg-inline--fa fa-walking fa-w-10 w-1/2" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512">
            <path fill="currentColor"
                  d="M208 96c26.5 0 48-21.5 48-48S234.5 0 208 0s-48 21.5-48 48 21.5 48 48 48zm94.5 149.1l-23.3-11.8-9.7-29.4c-14.7-44.6-55.7-75.8-102.2-75.9-36-.1-55.9 10.1-93.3 25.2-21.6 8.7-39.3 25.2-49.7 46.2L17.6 213c-7.8 15.8-1.5 35 14.2 42.9 15.6 7.9 34.6 1.5 42.5-14.3L81 228c3.5-7 9.3-12.5 16.5-15.4l26.8-10.8-15.2 60.7c-5.2 20.8.4 42.9 14.9 58.8l59.9 65.4c7.2 7.9 12.3 17.4 14.9 27.7l18.3 73.3c4.3 17.1 21.7 27.6 38.8 23.3 17.1-4.3 27.6-21.7 23.3-38.8l-22.2-89c-2.6-10.3-7.7-19.9-14.9-27.7l-45.5-49.7 17.2-68.7 5.5 16.5c5.3 16.1 16.7 29.4 31.7 37l23.3 11.8c15.6 7.9 34.6 1.5 42.5-14.3 7.7-15.7 1.4-35.1-14.3-43zM73.6 385.8c-3.2 8.1-8 15.4-14.2 21.5l-50 50.1c-12.5 12.5-12.5 32.8 0 45.3s32.7 12.5 45.2 0l59.4-59.4c6.1-6.1 10.9-13.4 14.2-21.5l13.5-33.8c-55.3-60.3-38.7-41.8-47.4-53.7l-20.7 51.5z"></path>
          </svg>

        </div>
        <div class="info">
          <div class="main">Blonkisoaz</div>
          <div class="sub">Omuke trughte a otufta</div>
        </div>
      </div>
    </div>
    <div
        class="option"
        @click="click"
        ref="div2"
        id="2"

        style="--optionBackground:url(https://66.media.tumblr.com/8b69cdde47aa952e4176b4200052abf4/tumblr_o51p7mFFF21qho82wo1_1280.jpg);">
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="snowflake"
               class="svg-inline--fa fa-snowflake fa-w-14 w-1/2" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 448 512">
            <path fill="currentColor"
                  d="M440.1 355.2l-39.2-23 34.1-9.3c8.4-2.3 13.4-11.1 11.1-19.6l-4.1-15.5c-2.2-8.5-10.9-13.6-19.3-11.3L343 298.2 271.2 256l71.9-42.2 79.7 21.7c8.4 2.3 17-2.8 19.3-11.3l4.1-15.5c2.2-8.5-2.7-17.3-11.1-19.6l-34.1-9.3 39.2-23c7.5-4.4 10.1-14.2 5.8-21.9l-7.9-13.9c-4.3-7.7-14-10.3-21.5-5.9l-39.2 23 9.1-34.7c2.2-8.5-2.7-17.3-11.1-19.6l-15.2-4.1c-8.4-2.3-17 2.8-19.3 11.3l-21.3 81-71.9 42.2v-84.5L306 70.4c6.1-6.2 6.1-16.4 0-22.6l-11.1-11.3c-6.1-6.2-16.1-6.2-22.2 0l-24.9 25.4V16c0-8.8-7-16-15.7-16h-15.7c-8.7 0-15.7 7.2-15.7 16v46.1l-24.9-25.4c-6.1-6.2-16.1-6.2-22.2 0L142.1 48c-6.1 6.2-6.1 16.4 0 22.6l58.3 59.3v84.5l-71.9-42.2-21.3-81c-2.2-8.5-10.9-13.6-19.3-11.3L72.7 84c-8.4 2.3-13.4 11.1-11.1 19.6l9.1 34.7-39.2-23c-7.5-4.4-17.1-1.8-21.5 5.9l-7.9 13.9c-4.3 7.7-1.8 17.4 5.8 21.9l39.2 23-34.1 9.1c-8.4 2.3-13.4 11.1-11.1 19.6L6 224.2c2.2 8.5 10.9 13.6 19.3 11.3l79.7-21.7 71.9 42.2-71.9 42.2-79.7-21.7c-8.4-2.3-17 2.8-19.3 11.3l-4.1 15.5c-2.2 8.5 2.7 17.3 11.1 19.6l34.1 9.3-39.2 23c-7.5 4.4-10.1 14.2-5.8 21.9L10 391c4.3 7.7 14 10.3 21.5 5.9l39.2-23-9.1 34.7c-2.2 8.5 2.7 17.3 11.1 19.6l15.2 4.1c8.4 2.3 17-2.8 19.3-11.3l21.3-81 71.9-42.2v84.5l-58.3 59.3c-6.1 6.2-6.1 16.4 0 22.6l11.1 11.3c6.1 6.2 16.1 6.2 22.2 0l24.9-25.4V496c0 8.8 7 16 15.7 16h15.7c8.7 0 15.7-7.2 15.7-16v-46.1l24.9 25.4c6.1 6.2 16.1 6.2 22.2 0l11.1-11.3c6.1-6.2 6.1-16.4 0-22.6l-58.3-59.3v-84.5l71.9 42.2 21.3 81c2.2 8.5 10.9 13.6 19.3 11.3L375 428c8.4-2.3 13.4-11.1 11.1-19.6l-9.1-34.7 39.2 23c7.5 4.4 17.1 1.8 21.5-5.9l7.9-13.9c4.6-7.5 2.1-17.3-5.5-21.7z"></path>
          </svg>
        </div>
        <div class="info">
          <div class="main">Oretemauw</div>
          <div class="sub">Omuke trughte a otufta</div>
        </div>
      </div>
    </div>
    <div class="option"
         @click="click"
         ref="div3"

         style="--optionBackground:url(https://66.media.tumblr.com/5af3f8303456e376ceda1517553ba786/tumblr_o4986gakjh1qho82wo1_1280.jpg);">
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tree"
               class="svg-inline--fa fa-tree fa-w-12 w-1/2" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 384 512">
            <path fill="currentColor"
                  d="M378.31 378.49L298.42 288h30.63c9.01 0 16.98-5 20.78-13.06 3.8-8.04 2.55-17.26-3.28-24.05L268.42 160h28.89c9.1 0 17.3-5.35 20.86-13.61 3.52-8.13 1.86-17.59-4.24-24.08L203.66 4.83c-6.03-6.45-17.28-6.45-23.32 0L70.06 122.31c-6.1 6.49-7.75 15.95-4.24 24.08C69.38 154.65 77.59 160 86.69 160h28.89l-78.14 90.91c-5.81 6.78-7.06 15.99-3.27 24.04C37.97 283 45.93 288 54.95 288h30.63L5.69 378.49c-6 6.79-7.36 16.09-3.56 24.26 3.75 8.05 12 13.25 21.01 13.25H160v24.45l-30.29 48.4c-5.32 10.64 2.42 23.16 14.31 23.16h95.96c11.89 0 19.63-12.52 14.31-23.16L224 440.45V416h136.86c9.01 0 17.26-5.2 21.01-13.25 3.8-8.17 2.44-17.47-3.56-24.26z"></path>
          </svg>
        </div>
        <div class="info">
          <div class="main">Iteresuselle</div>
          <div class="sub">Omuke trughte a otufta</div>
        </div>
      </div>
    </div>
    <div class="option"
         @click="click"
         ref="div4"

         style="--optionBackground:url(https://66.media.tumblr.com/5516a22e0cdacaa85311ec3f8fd1e9ef/tumblr_o45jwvdsL11qho82wo1_1280.jpg);">
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tint"
               class="svg-inline--fa fa-tint fa-w-11 w-1/2" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 352 512">
            <path fill="currentColor"
                  d="M205.22 22.09c-7.94-28.78-49.44-30.12-58.44 0C100.01 179.85 0 222.72 0 333.91 0 432.35 78.72 512 176 512s176-79.65 176-178.09c0-111.75-99.79-153.34-146.78-311.82zM176 448c-61.75 0-112-50.25-112-112 0-8.84 7.16-16 16-16s16 7.16 16 16c0 44.11 35.89 80 80 80 8.84 0 16 7.16 16 16s-7.16 16-16 16z"></path>
          </svg>
        </div>
        <div class="info">
          <div class="main">Idiefe</div>
          <div class="sub">Omuke trughte a otufta</div>
        </div>
      </div>
    </div>
    <div class="option"
         @click="click"
         ref="div5"
         style="--optionBackground:url(https://66.media.tumblr.com/f19901f50b79604839ca761cd6d74748/tumblr_o65rohhkQL1qho82wo1_1280.jpg);">
      <div class="shadow"></div>
      <div class="label">
        <div class="icon">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sun"
               class="svg-inline--fa fa-sun fa-w-16 w-1/2" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z"></path>
          </svg>
        </div>
        <div class="info">
          <div class="main">Inatethi</div>
          <div class="sub">Omuke trughte a otufta</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: "CardsNr3",


  data() {

    return {

      active: false,
    }

  },


  methods: {

    click(el) {


      this.$refs.div1.classList.remove('active')
      this.$refs.div2.classList.remove('active')
      this.$refs.div3.classList.remove('active')
      this.$refs.div4.classList.remove('active')
      this.$refs.div5.classList.remove('active')
      el.currentTarget.classList.add('active')

    }

  }
}
</script>

<style scoped lang="scss">
$optionDefaultColours: #ED5565, #FC6E51, #FFCE54, #2ECC71, #5D9CEC, #AC92EC;

body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  background: #232223;
  color: white;
  font-family: 'Roboto', sans-serif;
  transition: .25s;
  .credit {
    position: absolute;
    bottom: 20px;
    left: 20px;

    color: inherit;
  }

  .options {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;

    min-width: 600px;
    max-width: 900px;
    width: calc(100% - 100px);

    height: 400px;

    @for $i from 1 through 4 {
      @media screen and (max-width: 798px - $i*80) {
        min-width: 600px - $i*80;
        .option:nth-child(#{6-$i}) {
          display: none;
        }
      }
    }

    .option {
      position: relative;
      overflow: hidden;

      min-width: 60px;
      margin: 10px;
      //border:0px solid --defaultColor;

      background: var(--optionBackground, var(--defaultBackground, #E6E9ED));
      background-size: auto 120%;
      background-position: center;

      cursor: pointer;

      transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

      @for $i from 1 through length($optionDefaultColours) {
        &:nth-child(#{$i}) {
          --defaultBackground: #{nth($optionDefaultColours, $i)};
        }
      }

      &.active {
        flex-grow: 10000;
        transform: scale(1);

        max-width: 600px;
        margin: 0;
        border-radius: 40px;

        background-size: auto 100%;

        .shadow {
          box-shadow: inset 0 -120px 120px -120px black, inset 0 -120px 120px -100px black;
        }

        .label {
          bottom: 20px;
          left: 20px;

          .info > div {
            left: 0px;
            opacity: 1;
          }
        }

        /*&:active {
           transform:scale(0.9);
        }*/
      }

      &:not(.active) {
        flex-grow: 1;

        border-radius: 30px;

        .shadow {
          bottom: -40px;
          box-shadow: inset 0 -120px 0px -120px black, inset 0 -120px 0px -100px black;
        }

        .label {
          bottom: 10px;
          left: 10px;

          .info > div {
            left: 20px;
            opacity: 0;
          }
        }
      }

      .shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        height: 120px;

        transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      }

      .label {
        display: flex;
        position: absolute;
        right: 0;

        height: 40px;
        transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

        .icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          min-width: 40px;
          max-width: 40px;
          height: 40px;
          border-radius: 100%;

          background-color: white;
          color: var(--defaultBackground);
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;

          margin-left: 10px;

          color: white;

          white-space: pre;

          > div {
            position: relative;

            transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity .5s ease-out;
          }

          .main {
            font-weight: bold;
            font-size: 1.6rem;
            text-align: left;
          }

          .sub {
            transition-delay: .1s;
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}


</style>
